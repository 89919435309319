import React from 'react';
import ReactDOM from 'react-dom';
import { KatSpinner } from '@amzn/katal-react';
import { App } from './components/App';
import './i18n';
import styles from './styles/index.module.scss';

import { Amplify } from 'aws-amplify';

const callbackURL = window.location.origin;

Amplify.configure({
    Auth: {
        identityPoolId: 'us-east-1:43f3b578-aaf1-4c30-91c1-e2e5fa751b81',
        region: 'us-east-1',
        userPoolId: 'us-east-1_aXNSGZgUm',
        userPoolWebClientId: '4u1o3jf0utlnppc8eeoa2a39hn',
        oauth: {
            domain: 'gso-lanyard-gamma.auth.us-east-1.amazoncognito.com',
            scope: ['openid'],
            redirectSignIn: callbackURL,
            redirectSignOut: callbackURL,
            responseType: 'code',
        },
    }
});

// The Suspense component causes React to display the fallback KatSpinner until
// the i18n translation strings have been loaded.

ReactDOM.render(
    <React.StrictMode>
            <React.Suspense fallback={<KatSpinner size='large' />}>
                <App />
            </React.Suspense>
    </React.StrictMode>,
    document.getElementById('root')
);
